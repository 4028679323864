/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyBGKUi6VUFnbfawr0brN44Y6JGNDZD5BGU",
  "appId": "1:939829144832:web:c847282cd23457a76a97e5",
  "authDomain": "schooldog-i-baldwin-ga.firebaseapp.com",
  "measurementId": "G-E5XELBRX32",
  "messagingSenderId": "939829144832",
  "project": "schooldog-i-baldwin-ga",
  "projectId": "schooldog-i-baldwin-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-baldwin-ga.appspot.com"
}
